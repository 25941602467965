
function handleClick(e){
    e.preventDefault();
    if( this.closest('.toggle').classList.contains('active') ){
        this.closest('.toggle').classList.remove('active');
    } else {
        this.closest('nav').querySelectorAll('.toggle').forEach(i => i.classList.remove('active'));
        this.closest('.toggle').classList.add('active');
    }
}

document.querySelectorAll('.toggle').forEach(i => i.querySelector('.toggle__trigger').addEventListener('click', handleClick));
